import { gql, useQuery, useMutation } from '@apollo/client'
import { useState } from 'react'
import { sortBy } from 'puffy-core/collection'
import { getDeviceLocation, getDistance, getBearing } from '../utils/geoLocation'
import { GET_USER } from './user'
import { useUser } from './user'
import { is_plan_on } from '../utils/global'

const EMPTY_COORD = {}
const DISABLE_GEO_FENCING = process.env.REACT_APP_DISABLE_GEO_FENCING == 'true' 

export const LATEST_DATA = `
	latest_reading {
		id
		hazard
		tempdiff_h10_h1_avg
	}
`

const GET_TOWERS_GQL = (options?:any) => gql`
query get_towers_GQL {
	towers {
		data {
			id
			name
			online
			coord {
				lat
				long
				tz
			}
			permissions
			devices{
				serial_no
				status
			}
			${options?.includeLatestData ? LATEST_DATA : ''}
		}
	}
	${options?.includeUser ? GET_USER : ''}
}`

const TOGGLE_TOWERS = gql`
mutation towers_per_organization_update($tower_ids:[ID]!) {
	towers_per_organization_update(tower_ids:$tower_ids) {
		id
		name
		coord {
			lat
			long
			tz
		}
		permissions
		devices{
			serial_no
			status
		}
	}
}`

export const useToggleTowers = (options:any) => {
	const [update_towers, update_towers_op] = useMutation(TOGGLE_TOWERS)

	const toggle_towers = (tower_ids:any) => {
		update_towers({
			variables:{ tower_ids },
			...(options||{})
		})
	}

	return [toggle_towers, update_towers_op]
}

const _add_hazardous_status = (tower:any) => {
	if (tower && tower.latest_reading) {
		if (tower.latest_reading.hazard <= 0.2)
			tower.latest_reading = { ...tower.latest_reading, hazardous_status: 'present' }
		else if (tower.latest_reading.tempdiff_h10_h1_avg > 0)
			tower.latest_reading = { ...tower.latest_reading, hazardous_status: 'undecided' }
		else
			tower.latest_reading = { ...tower.latest_reading, hazardous_status: 'absent' }
	}

	return tower
}

const _transformData = (data:any, currentLoc:any, plan?:any) => {
	const user = (data?.users?.data || [])[0] || null
	const towers = _transformTowersByProximity(data?.towers?.data, currentLoc, user?.default_tower_id, plan)
	const defaultTower = towers.find((x:any) => x.isDefault) || null

	return {
		user,
		towers: towers.map(_add_hazardous_status),
		defaultTower,
		currentLocation: currentLoc
	}
}

export const useTowers = (options?:any) => {
	const user_op = useUser()
	const { user:includeUser, latestData:includeLatestData, onCompleted, onError } = options || {}
	const asyncDeviveLocation = getDeviceLocation()
	const queryOption:any = {}
	if (onCompleted)
		queryOption.onCompleted = async (data:any) => {
			const [,coord] = await asyncDeviveLocation
			onCompleted(_transformData(data, coord||EMPTY_COORD, user_op?.data?.plan?.name))
		}
	if (onError)
		queryOption.onError = onError

	const query = useQuery(GET_TOWERS_GQL({ includeUser, includeLatestData }), queryOption)
	const [currentLocation, setCurrentLocation] = useState<any | null>({})

	asyncDeviveLocation.then(([err, coord]:any) => {
		setCurrentLocation(coord || EMPTY_COORD)
	})

	return {
		...query,
		data: _transformData(query.data, currentLocation, user_op?.data?.plan?.name)
	}
}

/**
 * Gets the Google Map URL for a specific geo loc point.
 * 
 * @param	{Object}	coord
 * @param	{Number}		.lat
 * @param	{Number}		.long
 *
 * @return	{String}	url
 */
const _getGoogleMap = (coord:any) => {
	const { lat, long } = coord || {}
	if (lat !== undefined && long !== undefined)
		return `https://www.google.com/maps/place/${lat},${long}/@${lat},${long},10z`
	else
		return `https://www.google.com/maps/place`
}

/**
 * Adds to each item of the towers list the following properties:
 * 	- Distance in KM between the tower and the current location.
 * 	- Text description containing the distance and direction from the current location (e.g., 'distance 304 km, NNE').
 * 	- Google Map URL for the tower.
 *
 * The new array is also sorted by distance from the current location (ascending order)
 * 
 * @param	{[Object]}	towers[]
 * @param	{Object}		.coord
 * @param	{Number}			.latitude
 * @param	{Number}			.longitude
 * @param	{Object}			...rest
 * @param	{Object}	currentLoc
 * @param	{Number}		.latitude
 * @param	{Number}		.longitude
 * @param	{Number}	defaultTowerId
 *
 * @return	{[Object]}	sortedTowers[]
 * @return	{Object}		...originalprops		
 * @return	{Number}		.distance			Distance in KM between the tower and the current location.
 * @return	{Number}		.direction			Direction where the tower is from the current location (e.g., 'ENE').
 * @return	{String}		.serial_no			Current serial number
 * @return	{Number}		.googleMap			Google Map URL for the tower.
 * @return	{String}		.map_label			Label displayed on the Google map marker
 * @return	{Boolean}		.isDefault
 */
const _transformTowersByProximity = (towers:any, currentLoc:any, defaultTowerId?:any, plan?:string) => {

	const plan_on = is_plan_on()

	let _towers
	const free_plan = plan_on && (!plan || plan == 'free')
	if (currentLoc && typeof(currentLoc.latitude) == 'number' && typeof(currentLoc.longitude) == 'number') {
		_towers = (towers||[]).map((t:any) => {
			const { lat:latitude, long:longitude } = t.coord||{}
			const dist = getDistance(currentLoc, { latitude, longitude })
			const bearing = getBearing(currentLoc, { latitude, longitude })

			const distance:any = dist !== null && (dist*1) >= 0 ? dist.toFixed(2) : 0
			return { 
				...t, 
				distance: distance*1,
				plan: plan||'free',
				direction: bearing?.name,
				serial_no: (t?.devices||[])[0]?.serial_no,
				googleMap: _getGoogleMap(t.coord)
			}
		})
	} else
		_towers = (towers||[]).map((t:any) => ({ 
			...t, 
			distance: null,
			direction: null, 
			serial_no: (t?.devices||[])[0]?.serial_no,
			googleMap: _getGoogleMap(t.coord)
		}))

	let act_as_paid_user = false
	_towers = sortBy(_towers, (t:any) => t.distance).map((t:any, idx:number) => {
		const i = idx+1
		t.map_label = i < 10 ? `00${i}` : i < 100 ? `0${i}` : `${i}`
		return t
	}).map((t:any,idx:number) => {
		const no_permissions = !t.permissions || !t.permissions.length
		t.disabled = no_permissions
		t.act_as_paid_user = false

		if (DISABLE_GEO_FENCING) {
			t.disabled = false
			if (free_plan && !no_permissions && !act_as_paid_user)
				act_as_paid_user = true
			// t.act_as_paid_user = true
			// act_as_paid_user = true
		} else if (!free_plan)
			act_as_paid_user = true

		t.serial_with_id = `${t.serial_no} (ID ${t.id})`
		
		return t
	})

	const paid = act_as_paid_user || !free_plan
	_towers = _towers.map((t:any) => {
		t.act_as_paid_user = paid
		if (!plan_on && t.act_as_paid_user)
			t.disabled = false

		t.isDefault = !t.disabled && t?.id && t?.id == defaultTowerId

		return t
	})

	return _towers
}






